<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row">
      <div class="container-fluid">
        <MixedWidget1></MixedWidget1>
      </div>
    </div>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import MixedWidget1 from "@/view/content/widgets/mixed/Widget1.vue";

export default {
  name: "dashboard",
  components: {
    MixedWidget1,
  },
  mounted() {
  },
  methods: {
  },
};
</script>
